.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border: none;
}

.checkbox {
  --checkline-color: '#C4C4C4'
}

.mq-editable-field {
  width: 100%;
  border: 0px;
}
