@tailwind base;

@layer base {
  :root {
    color-scheme: light;
    --backgroundPrimary: 252 252 252;
    --backgroundSecondary: 248 248 248;
    --border: 226 226 226;
    --content1: 28 28 28;
    --content2: 88 87 87;
    --content3: 150 150 150;
    --whiteInverted: 0 0 0;
    --blackInverted: 255 255 255;
    --neutral: 105 113 119;
    --primary: 0 114 245;
    --secondary: 151 80 221;
    --success: 23 201 100;
    --error: 243 18 96;
    --warning: 255 184 0;
    --blue-1: 251 253 255;
    --blue-2: 245 250 255;
    --blue-3: 237 246 255;
    --blue-4: 225 240 255;
    --blue-5: 206 231 254;
    --blue-6: 183 217 248;
    --blue-7: 150 199 242;
    --blue-8: 94 176 239;
    --blue-9: 0 145 255;
    --blue-10: 0 129 241;
    --blue-11: 0 106 220;
    --blue-12: 0 37 77;
    --purple-1: 254 252 254;
    --purple-2: 253 250 255;
    --purple-3: 249 241 254;
    --purple-4: 243 231 252;
    --purple-5: 237 219 249;
    --purple-6: 227 204 244;
    --purple-7: 211 180 237;
    --purple-8: 190 147 228;
    --purple-9: 142 78 198;
    --purple-10: 132 69 188;
    --purple-11: 121 58 175;
    --purple-12: 43 14 68;
    --green-1: 251 254 252;
    --green-2: 242 252 245;
    --green-3: 233 249 238;
    --green-4: 221 243 228;
    --green-5: 204 235 215;
    --green-6: 180 223 196;
    --green-7: 146 206 172;
    --green-8: 91 185 140;
    --green-9: 48 164 108;
    --green-10: 41 151 100;
    --green-11: 24 121 78;
    --green-12: 21 50 38;
    --yellow-1: 253 253 249;
    --yellow-2: 255 252 232;
    --yellow-3: 255 251 209;
    --yellow-4: 255 248 187;
    --yellow-5: 254 242 164;
    --yellow-6: 249 230 140;
    --yellow-7: 239 211 108;
    --yellow-8: 235 188 0;
    --yellow-9: 245 217 10;
    --yellow-10: 247 206 0;
    --yellow-11: 148 104 0;
    --yellow-12: 53 41 15;
    --red-1: 255 252 252;
    --red-2: 255 248 248;
    --red-3: 255 239 239;
    --red-4: 255 229 229;
    --red-5: 253 216 216;
    --red-6: 249 198 198;
    --red-7: 243 174 175;
    --red-8: 235 144 145;
    --red-9: 229 72 77;
    --red-10: 220 61 67;
    --red-11: 205 43 49;
    --red-12: 56 19 22;
    --cyan-1: 250 253 254;
    --cyan-2: 242 252 253;
    --cyan-3: 231 249 251;
    --cyan-4: 216 243 246;
    --cyan-5: 196 234 239;
    --cyan-6: 170 222 230;
    --cyan-7: 132 205 218;
    --cyan-8: 61 185 207;
    --cyan-9: 5 162 194;
    --cyan-10: 8 148 179;
    --cyan-11: 12 119 146;
    --cyan-12: 4 49 60;
    --pink-1: 255 252 254;
    --pink-2: 255 247 252;
    --pink-3: 254 238 248;
    --pink-4: 252 229 243;
    --pink-5: 249 216 236;
    --pink-6: 243 198 226;
    --pink-7: 236 173 212;
    --pink-8: 227 142 195;
    --pink-9: 214 64 159;
    --pink-10: 210 49 151;
    --pink-11: 205 29 141;
    --pink-12: 59 10 42;
    --gray-1: 252 252 252;
    --gray-2: 248 248 248;
    --gray-3: 243 243 243;
    --gray-4: 237 237 237;
    --gray-5: 232 232 232;
    --gray-6: 226 226 226;
    --gray-7: 219 219 219;
    --gray-8: 199 199 199;
    --gray-9: 143 143 143;
    --gray-10: 133 133 133;
    --gray-11: 111 111 111;
    --gray-12: 23 23 23;
    --slate-1: 251 252 253;
    --slate-2: 248 249 250;
    --slate-3: 241 243 245;
    --slate-4: 236 238 240;
    --slate-5: 230 232 235;
    --slate-6: 223 227 230;
    --slate-7: 215 219 223;
    --slate-8: 193 200 205;
    --slate-9: 136 144 150;
    --slate-10: 126 134 140;
    --slate-11: 104 112 118;
    --slate-12: 17 24 28;
  }
  html {
    font-family: Inter, system-ui, sans-serif;
  }
  input:focus {
    outline: none
  }
}

@tailwind components;
@tailwind utilities;

@layer components {
  .btn-outline-gray {
    background-color: #FFF;
    border-color: #D0D5DD;
    border-width: 1px;
    color: #344054;
  }

  .btn-outline-gray:hover {
    background-color: #D0D5DD;
    color: #FFF;
  }

  #custom-jitsi-wrapper > div {
    height: 100%;
    width: 100%;
  }
}
